import {
  useClipboard as coreUseClipboard,
  useIntervalFn,
  type MaybeRef
} from '@vueuse/core'
import DOMPurify from 'dompurify'
import { debounce } from 'lodash-es'
import {
  ToastNotificationType,
  useGlobalToast
} from '~/lib/frontend/common/composables/toast'
import { resolveErrorMessage } from '~/lib/frontend/common/helpers/errors'

/**
 * Allow debounced value tracking, common in search input boxes
 */
export function useDebouncedInputValue(params?: {
  debounceBy?: number
  onValueUpdated?: (val: string) => void
}) {
  const { debounceBy = 1000, onValueUpdated } = params || {}
  const val = ref<string>('')

  const updateModelValueHandler = debounce((value: string) => {
    val.value = value
  }, debounceBy)

  if (onValueUpdated) {
    watch(val, (newVal, oldVal) => {
      if (newVal === oldVal) return
      onValueUpdated(newVal)
    })
  }

  return {
    value: computed(() => val.value),
    changeHandler: (params: { value: string }) => {
      updateModelValueHandler.cancel()
      val.value = params.value
    },
    updateModelValueHandler
  }
}

const purify = async (source: string) => {
  let purify: DOMPurify.DOMPurifyI

  if (process.server) {
    const jsdom = await import('jsdom')
    const window = new jsdom.JSDOM('').window
    purify = DOMPurify(window)
  } else {
    purify = DOMPurify
  }

  return purify.sanitize(source)
}

export function usePurifiedHtml(
  html: MaybeRef<string>,
  options?: Partial<{ key: string }>
) {
  const { key: keySuffix } = options || {}
  const key = computed(() => `usePurifiedHtml-${unref(html)}-${keySuffix}`)

  const { data } = useAsyncData(
    key.value,
    async () => {
      if (!unref(html)) return
      return purify(unref(html))
    },
    { watch: [key] }
  )

  return {
    purifiedHtml: computed(() => data.value || '')
  }
}

export const useReactiveNowDate = (
  params?: Partial<{
    /**
     * How often should the date be updated in milliseconds. Default: 1000ms
     */
    updateEvery: number
  }>
) => {
  const { updateEvery = 1000 } = params || {}

  const date = ref(new Date())

  useIntervalFn(() => {
    date.value = new Date()
  }, updateEvery)

  return date
}

/**
 * A wrapper over vueuse's useClipboard that also triggers toast notifications
 */
export const useClipboard = () => {
  // non-legacy doesn't seem to work in dev environments
  const { copy } = coreUseClipboard({ legacy: true })
  const { triggerNotification } = useGlobalToast()

  return {
    copy: async (
      text: string,
      options?: Partial<{
        successMessage?: string
        failureMessage?: string
      }>
    ) => {
      const successMessage = options?.successMessage || 'Value copied to clipboard'
      const failureMessage =
        options?.failureMessage || 'Failed to copy value to clipboard'

      try {
        await copy(text)
        triggerNotification({
          type: ToastNotificationType.Info,
          title: successMessage
        })
      } catch (e) {
        triggerNotification({
          type: ToastNotificationType.Danger,
          title: failureMessage,
          description: resolveErrorMessage(e)
        })
      }
    }
  }
}
